//export const URL = 'https://stt-api.acuizen.com';
// export const API_URL = 'https://stt-user-api.acuizen.com';
//export const API_URL = 'http://localhost:3039';
// export const API_URL = 'https://dev-sagt-user-api.acuizen.com';
export const API_URL = 'https://knowledge-api.acuizen.com';
export const CURRENT_USER_URL = API_URL + '/users/me';
export const LOCATION_ONE = API_URL + '/location-ones';
export const OBS_REPORTS =API_URL +'/observation-reports';
export const GET_INCIDENT_CIRCUMTANCE  ='https://stt-api.acuizen.com/incident-circumstance-categories'
export const GET_INCIDENT_CIRCUMTANCE_TYPE  ='https://stt-api.acuizen.com/incident-circumstance-types'
export const GET_SURFACE_TYPE  ='https://stt-api.acuizen.com/surface-types'
export const GET_SURFACE_CONDITION  ='https://stt-api.acuizen.com/surface-conditions'
export const GET_LIGHTING  ='https://stt-api.acuizen.com/lightings'
export const GET_WEATHER  ='https://stt-api.acuizen.com/weather-conditions'
export const GET_INCIDENT_CIRCUMTANCE_DESC  ='https://stt-api.acuizen.com/incident-circumstance-descriptions'
export const DCSO_APPROVER_LIST ='https://stt-api.acuizen.com/users/eptw-dcso-approver'
export const DCSO_HIGN_RISK_ASSESSOR =API_URL+'/eptw-construction-assessor'
export const INCIDENT_OWNER =API_URL +'/incident-owner-list'
export const INCIDENT_REPORT =API_URL +'/report-incidents'
export const PERMIT_REPORT =API_URL +'/permit-reports'
export const ACTION_LIST =API_URL +'/actions'
export const USER_ENTERPRISE_URL = (id) => {
    return API_URL + '/users/' + id +'/enterprises';
}
export const OBSERVATION_BY_ID =(id)=>{
    return API_URL + '/observation-reports/' + id ;
}

export const LOCATION_TWO = (id) => {
    return API_URL + '/location-ones/' + id + '/location-twos';
}
export const LOCATION_THREE = (id) => {
    return API_URL + '/location-twos/' + id + '/location-threes';
}
export const LOCATION_FOUR = (id) => {
    return API_URL + '/location-threes/' + id + '/location-fours';
}
export const LOCATION_FIVE = (id) => {
    return API_URL + '/location-fours/' + id + '/location-fives';
}
export const LOCATION_SIX = (id) => {
    return API_URL + '/location-fives/' + id + '/location-sixes';
}

export const LOCATION_TITLES = API_URL + '/dynamic-titles';

export const OBSERVATION_TWO = (id) => {
    return API_URL + '/ghs-ones/' + id + '/ghs-twos';
}

export const OBSERVATION_ONE = API_URL + '/ghs-ones';
export const WORK_ACTIVITIES = API_URL + '/work-activities';
export const ACTION_ASSIGNEE_LIST_OBS = API_URL + '/action-assignee-list';
export const ACTION_REVIEWER_LIST_OBS = API_URL + '/action-reviewer-list';
export const LIST_ACTION = API_URL + '/actions';
export const DOCUMENT =API_URL + '/user-list-documents';
export const DOCUMENT_CATEGORY =API_URL +'/document-categories';
export const UPLOAD_FILE =API_URL+'/files';

export const MARK_ACTION_READ = (id) => {
    return API_URL + '/actions-read/' + id;
}
export const OPEN_REPORTED_OBS = (id) => {
    return API_URL + '/observation-reports/' + id;
}

export const LOGIN_URL = API_URL + '/users/login';
export const ENTERPRISELOGIN_URL = API_URL + '/users/multilogin';
export const CHECK_ENTERPRISE =API_URL +'/users/checkenterprise'