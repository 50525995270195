

import React, { lazy, Suspense, useEffect } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,


} from "react-router-dom";
import Login from './page/login';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/custom_css.css'
import { useSelector } from 'react-redux';
import Return from './page/Return';
function App() {

  const Dashboard = lazy(() => import('./page/dashboard'));
  const Layout = lazy(() => import('./page/layout/Layout'));
  const OBSHome = lazy(() => import('./page/Obs/ObsHome'));
  const OBSView = lazy(() => import('./page/Obs/ViewObs'))
  const NewOBS = lazy(() => import('./page/Obs/NewOBSRecord'));
  const IncidentHome = lazy(() => import('./page/Incident/IncidentHome'));
  const NewIncident = lazy(() => import('./page/Incident/NewIncidentRecord'));
  const EPTWHome = lazy(() => import('./page/Eptw/EptwHome'));
  const NewEPTWTowerCrane = lazy(() => import('./page/Eptw/EptwTowerCrane'));
  const NewEPTWDCO = lazy(() => import('./page/Eptw/EptwDCO'));
  const NewEPTWConsActivity = lazy(() => import('./page/Eptw/EptwConsActivity'));
  const NewReport = lazy(() => import('./page/reports'));
  const IncidentView = lazy(() => import('./page/Incident/ViewIncident'))
  const EptwView = lazy(() => import('./page/Eptw/ViewEPTW'))
  const DcoView = lazy(() => import('./page/Eptw/ViewDco'))
  const DocView = lazy(() => import('./page/Document/View'))
  const DocSubmit =lazy(()=>import('./page/Document/Doc')) 
const DocDash =lazy(()=>import('./page/Document/Dash'))
  const isLogin = localStorage.getItem('access_token');

  return (
    <div className="container-scroller" >
      {/* <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
      > */}

      <Suspense fallback={<div>Loading...</div>}>


        <Routes >
          <Route
            path="*"
            element={!isLogin && <Navigate to="/" replace />}
          />

          <Route path='/login' element={<Return />} />

          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Layout type="first" />} >
            <Route index element={<Dashboard />} />
          </Route>
          <Route path="/obs_dashboard" element={<Layout type="two" name="Observation" />} >
            <Route index element={<OBSHome />} />
          </Route>
          <Route path="/new_obs" element={<Layout type="three" />} >
            <Route index element={<NewOBS />} />
          </Route>
          <Route path="/view_obs" element={<Layout type="three" />} >
            <Route index element={<OBSView />} />
          </Route>
          <Route path="/incident_dashboard" element={<Layout type="two" name="Incidents" />} >
            <Route index element={<IncidentHome />} />
          </Route>
          <Route path="/new_incident" element={<Layout type="three" />} >
            <Route index element={<NewIncident />} />
          </Route>
          <Route path="/eptw_dashboard" element={<Layout type="two" name="ePermit To Work" />} >
            <Route index element={<EPTWHome />} />
          </Route>
          <Route path="/new_eptw_tower_crane" element={<Layout type="three" />} >
            <Route index element={<NewEPTWTowerCrane />} />
          </Route>
          <Route path="/new_eptw_dco" element={<Layout type="three" />} >
            <Route index element={<NewEPTWDCO />} />
          </Route>
          <Route path="/view_incident" element={<Layout type="three" />} >
            <Route index element={<IncidentView />} />
          </Route>
          <Route path="/view_eptw" element={<Layout type="three" />} >
            <Route index element={<EptwView />} />
          </Route>
          <Route path="/new_eptw_cons_activity" element={<Layout type="three" />} >
            <Route index element={<NewEPTWConsActivity />} />
          </Route>
          <Route path="/view_dco" element={<Layout type="three" />} >
            <Route index element={<DcoView />} />
          </Route>
          <Route path="/new_report" element={<Layout />} >
            <Route index element={<NewReport />} />
          </Route>
          <Route path="/view_doc" element={<Layout type="backtwo" />} >
            <Route index element={<DocView />} />
          </Route>
          <Route path="/view" element={<Layout type="backtwo" />} >
            <Route index element={<DocSubmit />} />
          </Route>
          <Route path="/document" element={<Layout type="two" name="Documents" />} >
            <Route index element={<DocDash />} />
          </Route>
        </Routes>

      </Suspense>
      {/* </ThemeProvider> */}
    </div>
  );
}

export default App;
