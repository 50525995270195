import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { loginActions } from '../store/login-slice';
import Swal from 'sweetalert2';
import { CHECK_ENTERPRISE,LOGIN_URL,ENTERPRISELOGIN_URL } from "../constants";

const customSwal2 = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary',
  
    },
    buttonsStyling: false
  })

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [smShow, setSmShow] = useState(false)
    const [show, setShow] = useState(false)
    const [enterprise, setEnterprise] = useState([])
    const [selectedEnterprise, setSelectedEnterprise] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [femail, setFEmail] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const handleLogin = async (e) => {
        e.preventDefault();

        const email = e.target[0].value;
        const password = e.target[1].value;

        setEmail(email);
        setPassword(password);

        const login = await fetch(CHECK_ENTERPRISE, {
            method: "POST",
            body: JSON.stringify({
                email: email,
                password: password,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });

        if (login.ok) {
            const data = await login.json();
            if (data.length != 1) {
                setEnterprise(data)
                setShow(true)
            } else {
                const login = await fetch(LOGIN_URL, {
                    method: "POST",
                    body: JSON.stringify({
                        email: email,
                        password: password,

                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });

                if (login.ok) {
                    const data = await login.json();

                    localStorage.setItem("access_token", data.token);
                    dispatch(loginActions.setLogin());
                    navigate("/dashboard");
                    window.location.reload();
                } else {
                    customSwal2.fire(
                        'Please Try Again!',
                        '',
                        'error'
                    )
                }
            }


        } else {
            customSwal2.fire(
                'Please Try Again!',
                '',
                'error'
            )
        }
    };
  const closeEnterprise = async (item) => {
    console.log(item)



    const login = await fetch(ENTERPRISELOGIN_URL, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
        enterprise: item.id
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (login.ok) {
      const data = await login.json();

      localStorage.setItem("access_token", data.token);
      dispatch(loginActions.setLogin());
      navigate("/dashboard");
      window.location.reload();
    } else {
      customSwal2.fire(
        'Please Try Again!',
        '',
        'error'
      )
    }
  }

    return (
        <div className="container-fluid page-body-wrapper full-page-wrapper" style={{ height: '100vh', background: '#f5f5f5' }}>

            <div class="d-flex align-items-center auth px-0" style={{ height: '100%' }}>
                <div class="row w-100 mx-0">
                    <div class="col-lg-4 mx-auto">
                        <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                            <div class="brand-logo">
                                <img src={require('../assets/images/logo.png')} alt="logo" />
                            </div><h4>Hello! let's get started</h4>
                            <h6 class="font-weight-light">Sign in to continue.</h6>
                            <form class="pt-3" onSubmit={(e) => handleLogin(e)}>
                                <div class="d-flex search-field mb-3">
                                    <input placeholder="Username" type="email" class="h-auto form-control " />
                                </div>
                                <div class="d-flex search-field">
                                    <input placeholder="Password" type="password" class="h-auto form-control " />
                                </div>
                                <div class="mt-3">
                                    <button type="submit" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn btn btn-primary">SIGN IN</button>
                                    </div>
                                {/* <div class="my-2 d-flex justify-content-between align-items-center">
                                    <div class="form-check">
                                        <label class="form-check-label text-muted">
                                            <input type="checkbox" class="form-check-input" />
                                            <i class="input-helper"></i>Keep me signed in</label>
                                    </div>
                                    <a href="!#" class="auth-link text-black">Forgot password?</a>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                size="md"
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Enterprise</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        {enterprise.length !== 0 ?
                            enterprise.map(item => {
                                return (
                                    <div className="col-4 " onClick={() => closeEnterprise(item)}>
                                        <h6 className="login-box">{item.name}</h6>
                                    </div>
                                )
                            })

                            : ''

                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className="flex-wrap">


                

                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default Login